import React from 'react'
import {AnswerContext} from '../App'
import question1 from "../data/q1"
import question2 from "../data/q2"

export default function TestPage({handleOnSubmitClicked, handleOnContinueButtonClicked, handleOnPrintButtonClicked, handleOnResetClicked, disableForm}) {

  const {answers, setAnswers} = React.useContext(AnswerContext)

  function onCheckboxChanged(e, questionId, data) {
    setAnswers(questionId, data)
  }

  function renderQuestion(q) {
    return (
      <div className="form-item" key={q.name}>
        <input type="checkbox" disabled={disableForm} checked={answers[q.name]}
             onChange={e => onCheckboxChanged(e, q.name, !answers[q.name])}/>
        <div className="label">{q.label.zh} <br/> {q.label.en}</div>
        <div className="clearfix"></div>
      </div>
    )
  }

  return (
    <div className="mx-3">
      <h2 className="text-center mt-1">PART 2</h2>
      <hr />
      <h2 className="mb-1 mt-5">1. 婚姻是[在你认同的方格内画 (✔)]:</h2>
      <h4 className="mt-1">What do you think about marriage? Please tick (✔) if you agreed.</h4>
      {question1.map(q => renderQuestion(q))}
      <h2 className="mb-1 mt-5">2. 在你认同的句子画(✔) (至少一部分)</h2>
      <h4 className="mt-1">Check (✔) the statements you agree with (at least partially)</h4>
      {question2.map(q => renderQuestion(q))}
      <div className="text-center mt-5">
        {disableForm ? null :
          <button className="btn btn-primary" onClick={e => handleOnSubmitClicked(answers)}>完成 / DONE</button>}
        {disableForm ?
          <button className="btn btn-primary" onClick={e => handleOnPrintButtonClicked(answers)}>下载PDF / DOWNLOAD PDF</button> : null}
        {disableForm ?
          <button className="btn btn-secondary" onClick={handleOnContinueButtonClicked}>继续填写 / EDIT</button> : null}
        <button className="btn btn-secondary" onClick={e => handleOnResetClicked(answers)}>重新填写 / RESET</button>
      </div>
    </div>
  )
}
