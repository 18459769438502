import React from 'react';
import questionData1 from './data/q1';
import questionData2 from './data/q2';
import './App.css';
import TestPage from "./components/TestPage"
import jsPDF from 'jspdf';
import { unicodeMS } from './font/arial-unicode-ms-normal.js'
import * as ls from "local-storage"

const answersFromLS = ls.get("answers")
const answers = answersFromLS == null ? [...questionData1, ...questionData2].reduce((result, item) => {
  result[item.name] = false
  return result
}, {}) : answersFromLS

export const AnswerContext = React.createContext({
  answers
})

function App() {
  const setAnswers = (name, ans) => {
    updateAnswers(prevState => {
      const { answers } = prevState
      const newAnswers = { ...answers, [name]: ans }
      return { ...prevState, answers: newAnswers }
    })
  }

  const answerState = {
    answers,
    setAnswers
  }

  const [state, updateAnswers] = React.useState(answerState)

  const [showResults, updateShowResults] = React.useState(false)

  function handleOnSubmitClicked(answers) {
    ls.set("answers", answers)
    updateShowResults(true)
  }

  function handleOnResetClicked() {
    ls.remove("answers")
    updateShowResults(false)
    updateAnswers(prevState => {
      const initAnswers = [...questionData1, ...questionData2].reduce((result, item) => {
        result[item.name] = false
        return result
      }, {})
      return { ...prevState, answers: initAnswers }
    })
  }

  function handleOnContinueButtonClicked() {
    updateShowResults(false)
    window.scrollTo(0,0)
  }

  function handleOnPrintButtonClicked(answers1) {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4'
    })
    doc.addFileToVFS('unicodeMS.ttf', unicodeMS)
    doc.addFont('unicodeMS.ttf', 'unicodeMS', 'normal')
    doc.setTextColor(0, 0, 0)
    doc.setFillColor(135, 124, 45, 0)
    doc.setFont('unicodeMS')
    doc.setFontSize(14)
    let x = 30, y = 30

    doc.text("今日的婚姻：你和我 - Part 2", x, y)
    doc.setFontSize(12)
    y = y + 20
    // doc.text("在进入婚姻时，我们都会对男人、女人、婚姻抱有一套看法。", x, y + 15)
    // doc.text("这份问卷的目的是帮助你去观察一些会影响婚姻的思想、态度和期望。", x, y + 30)
    doc.text("1. 婚姻是[在你认同的方格内画 (✔)", x, y)
    y = y + 15
    doc.text("What do you think about marriage? Please tick (✔) if you agreed.", x, y)
    y = y + 15
    ///let yy = y + 75
    // console.log(answers1)
    questionData1.map(q => {
      y = y + 15
      const checkbox = answers1[q.name] ? "(✔)" : "(   )"
      doc.text(`${checkbox} ${q.label.zh} / ${q.label.en}`, x, y)
      return q
    })
    doc.addPage("a4", "p")
    y = 30
    doc.text("2. 在你认同的句子画(✔) (至少一部分)", x, y)
    y = y + 15
    doc.text("Check (✔) the statements you agree with (at least partially)", x, y)
    y = y + 15
    y = y + 15
    questionData2.map(q => {
      const checkbox = answers1[q.name] ? "(✔)" : "(   )"
      doc.text(`${checkbox} ${q.label.zh}`, x, y)
      y = y + 15
      const splitText = doc.splitTextToSize(q.label.en, 400)
      doc.text(splitText, x + 18, y)
      y = y + (splitText.length * 15)
      if (y > 580) {
        y = 30
        doc.addPage("a4", "p")
      }
      return q
    })
    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
      doc.save("marriagetoday-part2.pdf")
      // window.open(doc.output('bloburl', { filename: "marriagetoday-part2.pdf" }))
    } else {
      doc.save("marriagetoday-part2.pdf")
    }
  }

  return (
    <AnswerContext.Provider value={state}>
      <section className="container pb-5">
        <img src="/today_marriage_banner.png" className="w-100 banner-desktop" alt="今日的婚姻：你和我" title="今日的婚姻：你和我"/>
        <img src="/m_today_marriage_banner.png" className="w-100 banner-mobile" alt="今日的婚姻：你和我" title="今日的婚姻：你和我"/>
        <div>
          <TestPage handleOnSubmitClicked={handleOnSubmitClicked}
                    handleOnContinueButtonClicked={handleOnContinueButtonClicked}
                    handleOnPrintButtonClicked={handleOnPrintButtonClicked}
                    handleOnResetClicked={handleOnResetClicked}
                    disableForm={showResults}/>
        </div>
      </section>
      <footer className="mb-3">
        <div className="text-center">
          <div className="mb-1">2020 © 天主教婚前准备课程 | Catholic (Mandarin) Marriage Preparation Course. All rights
            reserved.
          </div>
          <div>
            <small className="text-muted">Developed by William T. &amp; Stella L.</small>
          </div>
        </div>
      </footer>
    </AnswerContext.Provider>
  );
}

export default App;
